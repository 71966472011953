<template>
  <div id="main">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  watch:{
  },
  methods: {
  },
  components: {
  },
  beforeMount() {
  },
  mounted() {
    var elt = document.getElementById("annee");
    if(elt != undefined){
      elt.innerText = new Date().getFullYear();
    }
  }
};
</script>
<style>
@import "assets/css/main.css";
</style>
