import Vue from "vue";
import VueRouter from "vue-router";
import Api from '@/Api';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/accueil'
  },  
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
    meta:{
      logged: false,
      header:"NonConnecter",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/stripe/:q",
    name: "Stripe",
    component: () => import("@/views/Stripe"),
    meta:{
      logged: false,
      header:"NonConnecter",
      title:"Pautions | Verification"
    }
  },
  {
    path: "/forgot/:uuid",
    name: "forgot",
    component: () => import("@/views/Forgot"),
    meta:{
      logged: false,
      header:"NonConnecter",
      title:"Pautions | Mon espace"
    }
  },
  /*{
    path: "/file/attestation/:uuid",
    name: "FileAttestation",
    component: () => import("@/views/file/Attestation"),
    meta:{
      logged: false,
      header:"NonConnecter",
      title:"Pautions"
    }
  },*/
  {
    path: "/redirect",
    name: "Redirect",
    component: () => import("@/views/Redirect"),
    meta:{
      logged: false,
      header:"NonConnecter",
      title:"Pautions"
    }
  },
  {
    path: "/activation/:uuid",
    name: "activation",
    component: () => import("@/views/Activation"),
    meta:{
      logged: false,
      header:"NonConnecter",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/accueil",
    name: "Accueil",
    component: () => import("@/views/home/Main"),
    meta:{
      logged: true,
      header:"Auto",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/v2/accueil",
    name: "Accueil",
    component: () => import("@/views/home/v2/Main"),
    meta:{
      logged: true,
      header:"Auto",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/zero-depot",
    name: "zero-depot",
    component: () => import("@/views/parcour/zero-depot/Main.vue"),
    meta:{
      logged:false,
      header:"ZeroDepot",
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/v2/zero-depot",
    name: "v2-zero-depot",
    component: () => import("@/views/parcour/v2-zero-depot/Main.vue"),
    meta:{
      logged:false,
      header:"ZeroDepot",
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/confirmation",
    name: "confirmation",
    component: () => import("@/views/Confirmation.vue"),
    meta:{
      logged:true,
      header:"ZeroDepot",
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/inscription/proprietaire",
    name: "inscription-proprietaire",
    component: () => import("@/views/inscription/Proprietaire.vue"),
    meta:{
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/inscription/locataire",
    name: "inscription-locataire",
    component: () => import("@/views/inscription/Locataire.vue"),
    meta:{
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/inscription/telechargement",
    name: "inscription-locataire",
    component: () => import("@/views/inscription/Telechargement.vue"),
    meta:{
      logged:true,
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin/Main.vue"),
    children:[
      {
        path: "sinistre",
        name: "admin-sinistre",
        component: () => import("@/views/admin/Sinistre.vue"),
        meta:{
          logged: true,
          header:"Admin",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "sinistre/:id",
        name: "admin-sinistre-detail",
        component: () => import("@/views/admin/SinistreDetail.vue"),
        meta:{
          logged: true,
          header:"Admin",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "inscrit",
        name: "admin-inscrit",
        component: () => import("@/views/admin/Inscrit.vue"),
        meta:{
          logged: true,
          header:"Admin",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "inscrit/:id",
        name: "admin-inscrit-detail",
        component: () => import("@/views/admin/InscritDetail.vue"),
        meta:{
          logged: true,
          header:"Admin",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "apporteur",
        name: "Apporteur",
        component: () => import("@/views/admin/apporteur/Main"),
        children:[
          {
            path:"liste",
            name:"ApporteurListe",
            component: () => import("@/views/admin/apporteur/Liste"),
            meta:{
              logged:true,
              header:"Admin",
              title:"Pautions | Assurance Zéro Dépôt"
            }
          },
          {
            path:"nouveau",
            name:"ApporteurNouveau",
            component: () => import("@/views/admin/apporteur/Detail"),
            meta:{
              logged:true,
              header:"Admin",
              title:"Pautions | Assurance Zéro Dépôt"
            }
          },
          {
            path:"detail/:id",
            name:"ApporteurDetail",
            component: () => import("@/views/admin/apporteur/Detail"),
            meta:{
              logged:true,
              header:"Admin",
              title:"Pautions | Assurance Zéro Dépôt"
            }
          }
        ]
      },
      {
        path: "partenaire",
        name: "Partenaire",
        component: () => import("@/views/admin/partenaire/Main"),
        children:[
          {
            path:"liste",
            name:"PartenaireListe",
            component: () => import("@/views/admin/partenaire/Liste"),
            meta:{
              logged:true,
              header:"Admin",
              title:"Pautions | Assurance Zéro Dépôt"
            }
          },
          {
            path:"nouveau",
            name:"PartenaireNouveau",
            component: () => import("@/views/admin/partenaire/Detail"),
            meta:{
              logged:true,
              header:"Admin",
              title:"Pautions | Assurance Zéro Dépôt"
            }
          },
          {
            path:"detail/:id",
            name:"PartenaireDetail",
            component: () => import("@/views/admin/partenaire/Detail"),
            meta:{
              logged:true,
              header:"Admin",
              title:"Pautions | Assurance Zéro Dépôt"
            }
          }
        ]
      },
      {
        path: "commission",
        name: "admin-commission",
        component: () => import("@/views/admin/Commission.vue"),
        meta:{
          logged: true,
          header:"Admin",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "statistique",
        name: "admin-statistique",
        component: () => import("@/views/admin/Statistique.vue"),
        meta:{
          logged: true,
          header:"Admin",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
    ],
    meta:{
      logged: true,
      header:"Admin",
      title:"Pautions | Mon espace"
    }
  },

  {
    path: "/partenaire",
    name: "partenaire",
    component: () => import("@/views/partenaire/Main.vue"),
    children:[
      {
        path: "integration",
        name: "partenaire-integration",
        component: () => import("@/views/partenaire/Integration.vue"),
        meta:{
          logged: true,
          header:"Partenaire",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "manuelle",
        name: "partenaire-manuelle",
        component: () => import("@/views/partenaire/Manuelle.vue"),
        meta:{
          logged: true,
          header:"Partenaire",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "liste",
        name: "partenaire-liste",
        component: () => import("@/views/partenaire/Liste.vue"),
        meta:{
          logged: true,
          header:"Partenaire",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "inscrit",
        name: "partenaire-inscrit",
        component: () => import("@/views/partenaire/Inscrit.vue"),
        meta:{
          logged: true,
          header:"Partenaire",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
    ],
    meta:{
      logged: true,
      header:"Partenaire",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/apporteur",
    name: "apporteur",
    component: () => import("@/views/apporteur/Main.vue"),
    children:[
      {
        path: "liste",
        name: "apporteur-liste",
        component: () => import("@/views/apporteur/Liste.vue"),
        meta:{
          logged: true,
          header:"Apporteur",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      },
      {
        path: "partenaire",
        name: "apporteur-partenaire",
        component: () => import("@/views/apporteur/Partenaire.vue"),
        meta:{
          logged: true,
          header:"Apporteur",
          title:"Pautions | Assurance Zéro Dépôt"
        }
      }
    ],
    meta:{
      logged: true,
      header:"Partenaire",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/mon-profil",
    name: "mon-profil",
    component: () => import("@/views/profil/Main.vue"),
    meta:{
      logged:true,
      header:"Souscri",
      title:"Pautions | Mon espace"
    }
  },
  {
    path: "/sinistre",
    name: "Sinistre",
    component: () => import("@/views/Sinistre"),
    meta:{
      logged:true,
      header:"Souscri",
      title:"Pautions | Assurance Zéro Dépôt"
    }
  },
  {
    path: "/link",
    name: "Link",
    component: () => import("@/views/Link"),
  },
];

const router = new VueRouter({
  routes,
});
async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.urlAPI + '/authorization/verify'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}
router.beforeEach((to, from, next) => {
  if(to.meta.title != undefined){
    document.getElementById("project-title").text = to.meta.title;
  }
  if(to.meta.logged === true){
    if(Api.getToken() != false){
      backVerify().then(res => {
        if(res.status === true){
          Api.saveToken(res.data.token);
          Api.saveUser(res.data);
          next();
        }else{
          next({path:'/login'})
        }
      }).catch( () => {
        next({path:'/login'})
      });
    }else{
      next({path:'/login'})
    }
  }else{
    next();
  }
})
export default router;
