import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Api from '@/Api';
import VueToastr from "vue-toastr";
Vue.use(VueToastr);
import {BIconSave} from 'bootstrap-vue'
Vue.component('BIconSave', BIconSave)
import VueGtag from "vue-gtag";

//import * as btcomponents from 'bootstrap-vue';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false;
Vue.use(VueGtag, {
  config: { id: "G-CLNR6N9SKH" }
})
// for (const component in btcomponents) {
//   Vue.component(component, btcomponents[component]);
// }
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
Api.init()
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    Api.$toastr = this.$toastr;
  }
}).$mount("#app");
