import router from "@/router";
import store from "@/store";

const Api = {
  init() {
    this.urlAPI = "https://api.preprod.pautions.fr";
    this.urlAPP = "https://preprod.pautions.fr";
    // this.urlAPI = "https://api.pautions.fr";
    // this.urlAPP = "https://app.pautions.fr";
    this.user = null;
    store.api = this;
    this.router = router;
    this.dev = "753951";
  },
  ajax(route, params = null, callBack) {
    const url = route.search('http') === 0 ? route : this.urlAPI + route;
    let the = this;
    let option = {};
    option.headers = {
      "Content-Type":"application/json",
      Authorization: this.getToken(),
    }
    if(this.dev != null){
      option.headers['appdev'] = this.dev;
    }
    if(params != null){
      option.method = "POST",
      option.body = JSON.stringify(params)
    }
    fetch(url,option).then( response => {
      if(response.status === 401){
        the.destroyToken();
        the.destroyUser();
      }
      response.json().then( data =>{
        if(data.status === false){
          this.$toastr.e(data.data);
        }
        the.res = data;
        if(callBack != null) {
          callBack(the.res);
        }
      })
    });
  },
  getToken(){
    var t = window.localStorage.getItem("token");
    if(t === undefined || t === null){
      return false;
    }
    return t;
  },
  saveToken(token){
    window.localStorage.setItem("token", token);
  },
  saveUser(user){
    this.user = user;
  },
  getUser(){
    return this.user;
  },
  destroyToken(){
    window.localStorage.removeItem("token");
    router.push({path:"/login"})
  },
  destroyUser(){
    this.user = null;
  },
  timestampToDate(timestamp,datetime = true){
    var date = new Date(timestamp*1000);
    var res = date.toLocaleDateString("fr-Fr");
    if(datetime){
      res += ' '+date.toLocaleTimeString("fr-Fr");
    }
    return res;
  },
  now(){
    var n = new Date().getTime();
    n = n/1000;
    return parseInt(n);
  },
  getParam(array,id){
    var check = array.find(a => a.id === id);
    if(check === undefined){
      return {}
    }
    return check;
  },
  initMenuMobile(){
    setTimeout(() => {
      var url = this.urlAPP+'/js/pautions-crm.js'
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', url);
      document.body.appendChild(recaptchaScript)
    }, 500);
  },
  price(nb){
    nb = parseFloat(nb);
    return nb.toFixed(2)
  },
  prix(price){
    return price.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    });
  },
};

export default Api;
